@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.cdnfonts.com/css/bamini'); */

@import url('https://fonts.googleapis.com/css2?family=Karla&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap'); */
* {
  font-family: 'Karla', sans-serif;
  /* font-family: 'Montserrat', sans-serif; */
}

.tamil {
  font-family: 'Bamini', sans-serif;
}


@page Section1 {
  size: auto;
  margin: .4in .5in .4in .4in;
  mso-header-margin: .5in;
  mso-footer-margin: .5in;
}

div.Section1 {
  page: Section1;
}



@page Section2 {
  size: auto;
  /* margin: .4in .5in .4in .4in; */
  mso-header-margin: .5in;
  mso-footer-margin: .5in;

}

div.Section2 {
  page: Section2;
  /* transform: scale(.9); */
}


@page Section3 {
  size: auto;
  /* margin: .4in .5in .4in .4in; */
  mso-header-margin: .5in;
  mso-footer-margin: .5in;

}

div.Section3 {
  page: Section3;
  transform: scale(.9);
  clear: both;
}

.active {
  background-color: #3363EB !important;
  color: white !important;
  font-weight: bold !important;
  border:2px solid #3363EB !important;
}